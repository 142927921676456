import React from 'react';

const Generateresult = ({ result, onCopy }) => {
    const copyResult = () => {
        onCopy(result);
    };

    return (
        <div className="d-flex text-center justify-content-center mt-3"	>
        <div className="result-box m-0 justify-content-center w-25">
            <p className="bg-secondary text-white rounded-2 fs-5">Angka Hoki: {result}</p>
            <button className="btn btn-sm btn-primary" onClick={copyResult}>Salin Angka</button>
        </div>
        </div>
    );
};

export default Generateresult;